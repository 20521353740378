import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Flex, FullPageStyles } from '../components/pageStyles'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <FullPageStyles>
      <Flex>
        <Flex>
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Flex>
      </Flex>
    </FullPageStyles>
  </Layout>
)

export default NotFoundPage
